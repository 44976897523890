import BadgeAndroid from "@app-assets/svg/badgeAndroid.svg";
import BadgeIOSSvg from "@app-assets/svg/badgeIOS.svg";
import { FaqItemProps } from "@app-components/faq/FaqSection";

import { CONTACT_EMAIL, DISPLAY_PHONE_NUMBER, PHONE_NUMBER } from "./common";

const contactParams = {
  displayPhoneNumber: DISPLAY_PHONE_NUMBER,
  phoneNumber: PHONE_NUMBER,
  contactEmail: CONTACT_EMAIL,
};

export const faqData: FaqItemProps[] = [
  {
    label: "page.faq.section1.label",
    items: [
      {
        label: "page.faq.section1.questions.q1.label",
        content: "page.faq.section1.questions.q1.content",
        subContent: [
          {
            label: "page.faq.section1.questions.q1.subContent.1.label",
            items: [
              "page.faq.section1.questions.q1.subContent.1.items.1",
              "page.faq.section1.questions.q1.subContent.1.items.2",
              "page.faq.section1.questions.q1.subContent.1.items.3",
              "page.faq.section1.questions.q1.subContent.1.items.4",
              "page.faq.section1.questions.q1.subContent.1.items.5",
            ],
          },
          {
            label: "page.faq.section1.questions.q1.subContent.2.label",
            items: [
              "page.faq.section1.questions.q1.subContent.2.items.1",
              "page.faq.section1.questions.q1.subContent.2.items.2",
              "page.faq.section1.questions.q1.subContent.2.items.3",
              "page.faq.section1.questions.q1.subContent.2.items.4",
              "page.faq.section1.questions.q1.subContent.2.items.5",
              "page.faq.section1.questions.q1.subContent.2.items.6",
            ],
          },
        ],
        summary: "page.faq.section1.questions.q1.summary",
        images: [
          { image: BadgeIOSSvg, url: "" },
          {
            image: BadgeAndroid,
            url: "https://play.google.com/store/apps/details?id=com.manytouches",
          },
        ],
      },
      {
        label: "page.faq.section1.questions.q2.label",
        content: "page.faq.section1.questions.q2.content",
      },
      {
        label: "page.faq.section1.questions.q3.label",
        content: "page.faq.section1.questions.q3.content",
      },
      {
        label: "page.faq.section1.questions.q4.label",
        content: "page.faq.section1.questions.q4.content",
      },
      {
        label: "page.faq.section1.questions.q5.label",
        content: "page.faq.section1.questions.q5.content",
      },
      {
        label: "page.faq.section1.questions.q6.label",
        content: "page.faq.section1.questions.q6.content",
      },
      {
        label: "page.faq.section1.questions.q7.label",
        content: "page.faq.section1.questions.q7.content",
      },
      {
        label: "page.faq.section1.questions.q8.label",
        html: "page.faq.section1.questions.q8.html",
        params: contactParams,
      },
    ],
  },
  {
    label: "page.faq.section2.label",
    items: [
      {
        label: "page.faq.section2.questions.q9.label",
        content: "page.faq.section2.questions.q9.content",
      },
      {
        label: "page.faq.section2.questions.q10.label",
        content: "page.faq.section2.questions.q10.content",
      },
    ],
  },
  {
    label: "page.faq.section3.label",
    items: [
      {
        label: "page.faq.section3.questions.q11.label",
        html: "page.faq.section3.questions.q11.html",
        params: contactParams,
      },
      {
        label: "page.faq.section3.questions.q12.label",
        html: "page.faq.section3.questions.q12.html",
        summary: "page.faq.section3.questions.q12.summary",
        params: contactParams,
      },
      {
        label: "page.faq.section3.questions.q13.label",
        content: "page.faq.section3.questions.q13.content",
      },
      {
        label: "page.faq.section3.questions.q14.label",
        content: "page.faq.section3.questions.q14.content",
      },
      {
        label: "page.faq.section3.questions.q15.label",
        html: "page.faq.section3.questions.q15.html",
        params: contactParams,
      },
      {
        label: "page.faq.section3.questions.q16.label",
        html: "page.faq.section3.questions.q16.html",
        params: contactParams,
      },
    ],
  },
  {
    label: "page.faq.section4.label",
    items: [
      {
        label: "page.faq.section4.questions.q1.label",
        content: "page.faq.section4.questions.q1.content",
      },
      {
        label: "page.faq.section4.questions.q2.label",
        content: "page.faq.section4.questions.q2.content",
      },
      {
        label: "page.faq.section4.questions.q3.label",
        content: "page.faq.section4.questions.q3.content",
      },
      {
        label: "page.faq.section4.questions.q4.label",
        content: "page.faq.section4.questions.q4.content",
      },
      {
        label: "page.faq.section4.questions.q5.label",
        html: "page.faq.section4.questions.q5.html",
        params: contactParams,
      },
    ],
  },
];
