import { useEffect, useState } from "react";

export const useWidthDetect = (width = "(max-width: 576px)") => {
  const [isMatch, setIsMatch] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia(width);
    console.log(mediaQuery);
    setIsMatch(mediaQuery.matches);

    const handleResize = () => {
      setIsMatch(mediaQuery.matches);
    };

    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isMatch;
};
